'use strict';


var s = new Snap('.swotster-svg'),

	swotster,
	initialise,
	loopAnimation,

	resize,
	winWidth,

	bookElems,
	iMacElem,
	notebookElem,
	notepenElem,
	pentabletElem,
	pincontElem,
	pencilElems,
	heroicon,
	courseIconElems,
	screenbgElem,


	setupImacAnim,
	setupNoteBookAnim,
	setupBookAnim,
	setupNotePenAnim,
	setupPentabletAnim,
	setupPenContAnim,
	setupPencilAnim,
	setupHeroiconAnim,
	setupHeroiconAnim_in,
	setupCourseIconAnim;

// shim layer with setTimeout fallback
window.requestAnimFrame = (function () {
	return  window.requestAnimationFrame       ||
		window.webkitRequestAnimationFrame ||
		window.mozRequestAnimationFrame    ||
		function (callback) {
			window.setTimeout(callback, 1000 / 60);
		};
})();


Snap.load(sw_conf.template_url + '/swotster.svg', function (response) {


	swotster = response;
	s.append(swotster);

	bookElems = s.selectAll('.book');
	iMacElem = s.select('.imac');
	notebookElem = s.selectAll('.notebook');
	notepenElem = s.select('.notepen');
	pentabletElem = s.select('.pentablet');
	pincontElem = s.select('.pincont');
	heroicon = s.select('.heroicon');
	pencilElems = s.selectAll('.pencil');
	courseIconElems = s.selectAll('.course-icon');
	screenbgElem = s.select('.screenbg');

	initialise();


	resize();

	winWidth = jQuery('body').width();

});

initialise = function () {
	loopAnimation();
};

resize = function(){

	if(jQuery('.swotster-svg').length){

		if(!jQuery('.swotster-svg #Layer_1').length);

		setTimeout(function(){
			resize();
		},500);

		if(winWidth <= 320){
			jQuery('.swotster-svg #Layer_1').attr('width','290').attr('height','153');
		}
		else if(winWidth <= 375){

			jQuery('.swotster-svg #Layer_1').attr('width','345').attr('height','182');

		}else{

			jQuery('.swotster-svg #Layer_1').attr('width','632.667').attr('height','333.667');
		}
	}

};

loopAnimation = function () {

	setupBookAnim();
	setupImacAnim();
	setupNoteBookAnim();

	setupNotePenAnim();
	setupPentabletAnim();
	setupPenContAnim();
	setupPencilAnim();
	setupHeroiconAnim();


	//window.onload = function(){
		setupCourseIconAnim();
	//};

	//setTimeout(loopAnimation, 7000);
};

setupHeroiconAnim = function(){

	heroicon.transform('r100,s0,0');
	heroicon.attr({
		"opacity": 0 // or dash-separated names
	});

	courseIconElems.forEach(function (elem) {
		elem.attr({
			"opacity": 0
		});
	});

};

setupHeroiconAnim_in = function(){



	setTimeout(function () {

		window.requestAnimFrame(function () {

			courseIconElems.attr({
				"opacity": 0
			});

			heroicon.animate({
				opacity: 1,
				transform:'r0, s1,1'
			}, 1000, mina.elastic);//


			screenbgElem.animate({
				fill: heroicon.attr('bgcolor')
			},300);


		});

	}, 300);

};

setupPenContAnim = function(){

	pincontElem.transform('t200,0, s1,1');

	setTimeout(function () {

		window.requestAnimFrame(function () {

			pincontElem.animate({
				transform: 't0,0 s1,1'
			}, 1000, mina.elastic);//

		});

	}, 1000);
};

setupPentabletAnim = function(){

	pentabletElem.transform('t200,0, s0,0');

	setTimeout(function () {

		window.requestAnimFrame(function () {

			pentabletElem.animate({
				transform: 't0,0 s1,1'
			}, 1000, mina.elastic);//

		});

	}, 1000);
};


setupNotePenAnim = function(){

	notepenElem.transform('t-200,0, s0,0');

	setTimeout(function () {

		window.requestAnimFrame(function () {

			notepenElem.animate({
				transform: 't0,0 s1,1'
			}, 1000, mina.elastic);//

		});

	}, 1500);
};


setupImacAnim = function(){

	notebookElem.forEach(function (elem) {
		elem.transform('t-200,0, s0,0');
	});


	setTimeout(function () {

		window.requestAnimFrame(function () {

			notebookElem.forEach(function (elem) {

				elem.animate({
					transform: 't0,0 s1,1'
				}, 500, mina.elastic);//

			});
		});

	}, 800);

};



setupNoteBookAnim = function(){

	iMacElem.transform('t0,0, s.5,.5');
	iMacElem.attr({
		"opacity": 0 // or dash-separated names
	});

	setTimeout(function () {

		window.requestAnimFrame(function () {

			iMacElem.animate({
					transform: 't0,0 s1,1',
					opacity:1
			}, 1000, mina.elastic);//

		});

	}, 1000);
};


setupBookAnim = function () {

	var x = 1;
	bookElems.forEach(function (elem) {

		if(x > 3 ){
			elem.transform('t0,-200');
		}else{
			elem.transform('t-200,0');
		}
		elem.attr({
			"fill-opacity": 0 // or dash-separated names
		});
		x++;
	});

	var a = 1;
	var x = 500;
	var y = 100;

	setTimeout(function () {

		window.requestAnimFrame(function () {

			bookElems.forEach(function (elem) {

				(function(e,a){

					setTimeout(function(){

						if(a == 5){
							e.animate({
								transform: 'r-7, t-8,0 s1,1'
							}, x, mina.elastic);//mina.elastic

							setTimeout(function(){
								e.transform('r0');
							},2000);

						}else{
							e.animate({
								transform: 't0,0 s1,1'
							}, x, mina.elastic);//mina.elastic
						}

						x = x+50;

						e.attr({
							"fill-opacity": 1
						});



					},y);


				})(elem,a);

				y = y + 200;
				a++;

			});

		});

	}, 1000);


	/*
	 bookElems.forEach(function (elem) {
	 elem.transform('r60,250,453 s0,0');
	 });


	 setTimeout(function () {

	 window.requestAnimFrame(function () {

	 bookElems.animate({
	 transform: 'r0, 250,453 s1,1'
	 }, 1500, mina.elastic);

	 });
	 }, 800);
	 */



};


setupPencilAnim = function () {


	var x = 1;
	pencilElems.forEach(function (elem) {

		elem.transform('t0,-200');
		elem.attr({
			"fill-opacity": 0 // or dash-separated names
		});

		x++;
	});

	var a = 1;
	var x = 500;
	var y = 100;

	setTimeout(function () {

		window.requestAnimFrame(function () {

			pencilElems.forEach(function (elem) {

				(function(e,a){

					setTimeout(function(){

						e.animate({
							transform: 't0,0 s1,1'
						}, x, mina.elastic);//mina.elastic

						x = x+50;

						e.attr({
							"fill-opacity": 1
						});

					},y);


				})(elem,a);

				y = y + 200;
				a++;

			});

		});

	}, 1000);


};



setupCourseIconAnim = function () {




	var a = 1;
	var y = 500;

	setTimeout(function () {

		window.requestAnimFrame(function () {

			courseIconElems.forEach(function (elem) {

				(function(e,a){

					setTimeout(function(){

						courseIconElems.attr({
							"opacity": 0
						});

						screenbgElem.attr({
							fill: e.attr('bgcolor')
						});

						e.attr({
							"opacity": 1
						});


						if(a == courseIconElems.length){

							setupHeroiconAnim_in();
						}


					},y);


				})(elem,a);

				y = y + 200;
				a++;

			});

		});

	}, 800);


};