var winWidth = 0, bookMarkedClicked = false;


function debounce(a,b,c){var d;return function(){var e=this,f=arguments;clearTimeout(d),d=setTimeout(function(){d=null,c||a.apply(e,f)},b),c&&!d&&a.apply(e,f)}}


function doPaymentTransaction(){

  var form$ = jQuery("#ebook-payment-form");

  jQuery.ajax({
    type: "POST",
    dataType: "json",
    cache: false,
    url: sw_conf.ajax_url,
    data: form$.serializeArray(),
    success: function(response) {

      jQuery('.ajax-loading').hide();

      if(response.success){
        jQuery(".payment-errors").addClass('alert alert-success').html(response.msg);
        form$.find("input[type=text], textarea").val("");
      }else{
        jQuery(".payment-errors").addClass('alert alert-danger').html(response.msg);
      }

      jQuery('.submit-button').removeAttr("disabled");

    },
    error: function(){

      jQuery('.ajax-loading').hide();
      jQuery('.submit-button').removeAttr("disabled");
      alert('Please try again.');
    }
  });

}

function stripeResponseHandler(status, response) {

  if (response.error) {

    // re-enable the submit button
    jQuery('.submit-button').removeAttr("disabled");
    // show the errors on the form
    jQuery(".payment-errors").addClass('alert alert-danger').html(response.error.message);
    jQuery('.ajax-loading').hide();

  } else {

    var form$ = jQuery("#ebook-payment-form");
    // token contains id, last4, and card type
    var token = response['id'];
    // insert the token into the form so it gets submitted to the server
    form$.append("<input type='hidden' name='stripeToken' value='" + token + "' />");
    // and submit
    //form$.get(0).submit();


    doPaymentTransaction();

  }
}

function InitStripeEbookPaymentForm() {

  jQuery("#ebook-payment-form").submit(function (event) {


    jQuery('.ajax-loading').show();
    jQuery(".payment-errors").removeClass('alert alert-danger').html('');

    // disable the submit button to prevent repeated clicks
    jQuery('.submit-button').attr("disabled", "disabled");



    var pm_key = jQuery("input[type='radio'][name='pm']:checked").val();

    //has selected payment method for loggedin user
    if(pm_key != '' && pm_key != undefined){

      doPaymentTransaction();

    }else{

      // createToken returns immediately - the supplied callback submits the form if there are no errors
      Stripe.createToken({
        number: jQuery('.card-number').val(),
        cvc: jQuery('.card-cvc').val(),
        exp_month: jQuery('.card-expiry-month').val(),
        exp_year: jQuery('.card-expiry-year').val()
      }, stripeResponseHandler);

    }

    return false; // submit from callback
  });

}


function addStripePaymentMethodResponseHandler(status, response) {

  if (response.error) {

    // re-enable the submit button
    jQuery('.submit-button').removeAttr("disabled");
    // show the errors on the form
    jQuery(".payment-errors").addClass('alert alert-danger').html(response.error.message);
    jQuery('.ajax-loading').hide();

  } else {

    var form$ = jQuery("#add-payment-form");
    // token contains id, last4, and card type
    var token = response['id'];
    // insert the token into the form so it gets submitted to the server
    form$.append("<input type='hidden' name='stripeToken' value='" + token + "' />");
    // and submit
    //form$.get(0).submit();


    jQuery.ajax({
      type: "POST",
      dataType: "json",
      cache: false,
      url: sw_conf.ajax_url,
      data: form$.serializeArray(),
      success: function(response) {

        jQuery('.ajax-loading').hide();

        if(response.success){

          jQuery(".payment-errors").addClass('alert alert-success').html(response.msg);
          form$.find("input[type=text], textarea").val("");
          window.location.reload();

        }else{
          jQuery(".payment-errors").addClass('alert alert-danger').html(response.msg);
        }

        jQuery('.submit-button').removeAttr("disabled");

      },
      error: function(){

        jQuery('.ajax-loading').hide();
        jQuery('.submit-button').removeAttr("disabled");

        alert('Please try again.');
      }
    });

  }
}

function InitAddStripePaymentMethod() {

  jQuery("#add-payment-form").submit(function (event) {

    jQuery('.ajax-loading').show();
    jQuery(".payment-errors").removeClass('alert alert-danger').html('');

    // disable the submit button to prevent repeated clicks
    jQuery('.submit-button').attr("disabled", "disabled");

    // createToken returns immediately - the supplied callback submits the form if there are no errors
    Stripe.createToken({
      number: jQuery('.card-number').val(),
      cvc: jQuery('.card-cvc').val(),
      exp_month: jQuery('.card-expiry-month').val(),
      exp_year: jQuery('.card-expiry-year').val()
    }, addStripePaymentMethodResponseHandler);
    return false; // submit from callback
  });

}

function openModal(){

    setTimeout(function(){

      jQuery('body').addClass('modal-open');
    },1000);
}



function InitLogInForm(){

  var $loginform = jQuery("#loginform");

  $loginform.submit(function(){


    if(jQuery('#user_login').val() == '' || jQuery('#user_pass').val() == '')
      return false;


    $loginform.find('.ajax-loading').show();

    jQuery('#login-message').removeClass('alert alert-danger').hide();

    jQuery.ajax({

      type: "POST",
      dataType: "json",
      url: $loginform.attr('action'),
      data: $loginform.serializeArray(),
      success: function(response) {

        //var $response = jQuery(response);

        $loginform.find('.ajax-loading').hide();

        //if ( $response.find('#login_error').length ) {
        if ( !response.success ) {

          jQuery('#login-message').addClass('alert alert-danger').html(response.msg).show();

        }else {

          jQuery('#login-message').addClass('alert alert-success').html(response.msg).show();

          //jQuery('#login-message').remove();
          //window.location.href = '<?php echo home_url('/my-account/')?>';
          //window.location.reload();


          if(bookMarkedClicked){

            jQuery('a.bookmark-lesson').trigger('click');

          }else{

            if(response.redirect_to != '' && response.redirect_to != undefined){

              window.location.href = response.redirect_to;

            }else{
              window.location.reload();
            }
          }

        }

      },
      error: function(){

        $loginform.find('.ajax-loading').hide();

      }
    });

    return false;
  });

}

function InitRegForm(){

  var $regform = jQuery('#create-account-form');

  $regform.submit(function(){

      $regform.find('.ajax-loading').show();

      jQuery('#reg-message').removeClass('alert alert-danger').hide();

      jQuery.ajax({

        type: "POST",
        dataType: "json",
        url: sw_conf.ajax_url,
        data: $regform.serializeArray(),
        success: function(response) {


          $regform.find('.ajax-loading').hide();

          if ( response.success ) {

            jQuery('#reg-message').hide();
            window.location.href = response.redirect_to;
            //window.location.reload();

          }else {

            jQuery('#reg-message').addClass('alert alert-danger').show().html(response.msg);

          }

        },
        error: function(){

          $regform.find('.ajax-loading').hide();
        }
      });

      return false;

  });


}


function InitResetPassForm(){


  var $resetPassform = jQuery('#reset-pass-form');

  $resetPassform.submit(function(){

    $resetPassform.find('.ajax-loading').show();

    jQuery('#reset-pass-message').removeClass('alert alert-danger').hide();

    jQuery.ajax({

      type: "POST",
      dataType: "json",
      url: sw_conf.ajax_url,
      data: $resetPassform.serializeArray(),
      success: function(response) {

        $resetPassform.find('.ajax-loading').hide();

        if ( response.success ) {

          //jQuery('#reset-pass-message').hide();
          //window.location.href = '<?php echo home_url('/my-account/')?>';
          //window.location.reload();

          $resetPassform.find('.form-control').val('');
          jQuery('#reset-pass-message').addClass('alert alert-success').show().html(response.msg);

        }else {

          jQuery('#reset-pass-message').addClass('alert alert-danger').show().html(response.msg);

        }

      },
      error: function(){

        $resetPassform.find('.ajax-loading').hide();
      }
    });

    return false;

  });

}


function InitAccountForm(){

  var $regform = jQuery('.account-form');

  var $responsemessage = jQuery('#response-message');

  $regform.submit(function(){

    $regform.find('.ajax-loading').show();

    jQuery('#response-message').removeClass('alert alert-danger alert-success').hide();

    jQuery.ajax({

      type: "POST",
      dataType: "json",
      url: sw_conf.ajax_url,
      data: $regform.serializeArray(),
      success: function(response) {

        $regform.find('.ajax-loading').hide();

        if ( response.success ) {

          $responsemessage.addClass('alert alert-success').show().html(response.msg);

        }else {

          $responsemessage.addClass('alert alert-danger').show().html(response.msg);
        }

      },
      error: function(){

        $regform.find('.ajax-loading').hide();
        alert('Error: Please try again.');

      }
    });

    return false;

  });

}


function SubscribeNewsletterForm(){

  var $subscribeform = jQuery('.subscribe-form');

  var $responsemessage = jQuery('#subscribe-response-message');

  $subscribeform.submit(function(){

    $subscribeform.find('.email-field').addClass('loading');

    $responsemessage.removeClass('alert alert-danger alert-success').hide();

    jQuery.ajax({

      type: "POST",
      dataType: "json",
      url: sw_conf.ajax_url,
      data: $subscribeform.serializeArray(),
      success: function(response) {

        $subscribeform.find('.email-field').removeClass('loading');

        if ( response.success ) {

          $responsemessage.addClass('alert alert-success').show().html(response.msg);
          $subscribeform.find('.email-field').val('');

        }else {

          $responsemessage.addClass('alert alert-danger').show().html(response.msg);
        }

      },
      error: function(){

        $subscribeform.find('.email-field').removeClass('loading');
        alert('Error: Please try again.');

      }
    });

    return false;

  });

}

function HeaderSearchForm(){

  jQuery('.head-search a').on('click',function(){
    jQuery('.banner .search-form').slideToggle('fast');
  });

}

function SidebarSearchForm(){

  jQuery('select#search-post-type-filter').change(function(){
    jQuery(this).parents('form').attr('class','search-'+jQuery(this).val());
  });

}

function BookmarkLesson(){

  jQuery('a.bookmark-lesson').on('click',function(){


    var $lessonBookmarkLink = jQuery(this);
    var lessonId = jQuery(this).data('lesson-id');
    var removebookmark = jQuery(this).data('remove-bookmark');

    jQuery.ajax({

      type: "POST",
      dataType: "json",
      url: sw_conf.ajax_url,
      data: 'action=bookmark-lesson&id='+lessonId+'&from_login='+bookMarkedClicked,
      success: function(response) {



        if(removebookmark){

          if((response.status == 1  || response.status == 0)){

            $lessonBookmarkLink.parents('tr').remove();

          }else{

            window.location.reload();

          }

          return;
        }

        if(response.status == 1){

          $lessonBookmarkLink.addClass('bookmarked');
          $lessonBookmarkLink.addClass('bookmarkdone');

          if(bookMarkedClicked){
            window.location.reload();
          }

        }

        else if ( response.status == 0) {

          $lessonBookmarkLink.addClass('bookmarkdone');
          $lessonBookmarkLink.removeClass('bookmarked');

          if(bookMarkedClicked){
            window.location.reload();
          }

        }

        else if(response.status == -1){

          bookMarkedClicked = true;
          jQuery('.login-modal').modal('show');

        }

        setTimeout(function(){

          $lessonBookmarkLink.removeClass('bookmarkdone');

        },3000);

      },

      error: function(){

        alert('Error: Please try again.');

      }

    });

    return false;
  });

}

(function($) {


  var Swotster = {
    // All pages
    'common': {

      init: function() {


        InitStripeEbookPaymentForm();
        InitLogInForm();
        InitRegForm();
        InitResetPassForm();
        InitAccountForm();
        InitAddStripePaymentMethod();
        HeaderSearchForm();
        SubscribeNewsletterForm();
        SidebarSearchForm();
        BookmarkLesson();

        winWidth = $('body').width();

        window.ScrollReveal = new window.scrollReveal({
          //reset: true,
          //move: '50px',
          vFactor:  0.20,
          mobile: true
        });

        var resizeEvent = debounce(function() {

          winWidth = $('body').width();

        }, 250);


        var scrollEvent = function() {

          var st = jQuery(document).scrollTop();

          if(st >= 50 && winWidth >= 768){
            $('body').addClass('fixed-nav');
          }else{
            $('body').removeClass('fixed-nav');
          }

        };

        window.addEventListener('scroll', scrollEvent);
        window.addEventListener('resize', resizeEvent);

        $('a.nav-toggle').on('click',function(){

          $('.head-nav').slideToggle('fast');

        });


        $('.lesson-dropdown .lesson-dropdown-wrap, .dclose').on('click',function(){

            $('.lesson-dropdown').removeClass('pakita');

        });

        $('.lesson-dropdown a.trigger').on('click',function(){

          $('.lesson-dropdown').toggleClass('pakita');

        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }

    },




    // Home page
    'home': {

      init: function() {

        $('.testimonial-bxslider').bxSlider({
          slideWidth: '450',
          minSlides: 1,
          maxSlides: 2,
          auto: true,
          controls: false,
          infiniteLoop: false

          //autoControls: true
        });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };


  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Swotster;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery);

